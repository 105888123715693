import React from "react"
import PropTypes from "prop-types"
import loadable from "@loadable/component"
import { FcInfo } from "react-icons/fc"
import "react-tooltip/dist/react-tooltip.css"
import { Tooltip } from "react-tooltip"

import {
  formatDate,
  formatDateFullYear,
  formatFullDate,
} from "../../helpers/dates"

const FormLink = loadable(() => import("../../components/FormLink"))

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const Date = ({ dates, highlights, page }) => {
  const language = process.env.GATSBY_SITE_LANGUAGE

  const date = dates[0]
  const prePaidPrice = date?.prePaidPrice
  const standardPrice = date?.standardPrice

  return (
    <div className="relative text-gray-800">
      {date.startDate && date.endDate && (
        <h4 className="mb-4 font-bold text-black">
          {formatDate(date.startDate)} - {formatDateFullYear(date.endDate)}
        </h4>
      )}
      {date.spaces && (
        <span className="absolute top-0 right-0 px-4 py-2 -mt-2 text-xs text-white bg-red-400 rounded-full">
          {date.spaces} spots left!
        </span>
      )}
      {highlights && (
        <ul className="mb-4 checkListAlt">
          {highlights.map((item, index) => (
            <li key={`highlight_${index}`}>
              <span>{item}</span>
            </li>
          ))}
        </ul>
      )}

      <div className="flex gap-4 items-center mb-4 w-full">
        <div className="flex mr-auto text-sm">
          <strong>
            {language === "en" ? "Early Bird Rate" : "Tariffa Early Bird"}
          </strong>
          <Tooltip id="early-bird" place="left" />
          <a
            data-tooltip-id="early-bird"
            data-tooltip-html={
              language === "en"
                ? "This temporary rate with 20% discount on<br />the Standard Rate requires a 25% non-refundable<br /> deposit. The rest to be paid one month<br /> before arrival"
                : "Questa tariffa temporanea con 20% sconto sulla tariffa standard<br />richiede un deposito non rimborsabile del 25%. Il resto deve<br /> essere pagato un mese prima dell'arrivo."
            }
          >
            <FcInfo className="ml-1" />
          </a>
        </div>
        <div className="mx-1 shrink-0">
          <p className="text-xs">
            <em>{language === "en" ? "Price from" : "Prezzo da"}</em>
          </p>
          <p>
            €{numberWithCommas(prePaidPrice)}
            <span className="text-sm">p.p.</span>
          </p>
        </div>
        <div className="w-[105px] shrink-0">
          <FormLink
            linkLabel={language === "en" ? "Book Now" : "Prenota ora"}
            form="Retreat Enquiry - Early"
            page={page}
            type="buttonBooking"
            rate="Early Bird Rate"
          />
        </div>
      </div>
      <div className="flex gap-4 items-center w-full">
        <div className="flex mr-auto text-sm">
          <strong>
            {language === "en" ? "Standard Rate" : "Tariffa Standard"}
          </strong>
          <Tooltip id="standard-price" place="left" />
          <a
            data-tooltip-id="standard-price"
            data-tooltip-html={
              language === "en"
                ? "This rate requires a 25% refundable<br /> deposit if cancelled at least one month before<br /> arrival. The rest to be paid upon arrival."
                : "Questa tariffa richiede un deposito rimborsabile<br />del 25% se annullata almeno un mese prima<br /> dell'arrivo. Il resto da pagare prima o all'arrivo."
            }
          >
            <FcInfo className="ml-1" />
          </a>
        </div>
        <div className="mx-1 shrink-0">
          <p className="text-xs">
            <em>{language === "en" ? "Price from" : "Prezzo da"}</em>
          </p>
          <p>
            €{numberWithCommas(standardPrice)}
            <span className="text-sm">p.p.</span>
          </p>
        </div>
        <div className="w-[105px] shrink-0">
          <FormLink
            linkLabel={language === "en" ? "Book Now" : "Prenota ora"}
            form="Retreat Enquiry - Standard"
            page={page}
            type="buttonBooking"
            rate="Standard Rate"
          />
        </div>
      </div>
    </div>
  )
}

export default Date

Date.propTypes = {
  dates: PropTypes.array,
  highlights: PropTypes.array,
  page: PropTypes.string,
}
